<template>
  <v-dialog max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" fab color="primary">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Ajouter une pièce jointe</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="entrepriseAttachmentForm">
          <v-file-input :rules="[notNullNorEmpty]" label="Fichier" v-model="entrepriseAttachment.file" chips></v-file-input>
          <v-select :rules="[notNullNorEmpty]" item-value="value" item-text="value" :items="entreprise_attachments_types" label="Type de document" v-model="entrepriseAttachment.type"></v-select>
          <date-field label="Date maximum de validité" v-model="entrepriseAttachment.date_validite"></date-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="uplaoding" color="primary" @click="addDocument">Ajouter le document</v-btn>
        <v-progress-circular v-if="uplaoding" indeterminate color="primary"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateField from "@/components/base/DateField";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
import {EntrepriseAttachmentRepository} from "@/repository/entreprises";

export default {
  name: 'EntrepriseAttachmentDialog',
  components: {DateField},
  mixins: [ValidationRulesMixin],
  props: {
    entreprise: {
      type: Object,
      default: () => {},
    }
  },
  async mounted() {
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
  },
  data() {
    return {
      repository: new EntrepriseAttachmentRepository(),
      entrepriseAttachment: {
        file: null,
        type: "",
        date_validite: null,
        entreprise: this.entreprise.id,
      },
      uplaoding: false,
    }
  },
  methods: {
    async addDocument() {
      if(this.entrepriseAttachment.date_validite === null) {
        delete this.entrepriseAttachment.date_validite;
      }
      await this.repository.postFile(this.entrepriseAttachment);
      this.$emit("reload");
      this.entrepriseAttachment = {
        file: null,
        type: "",
        date_validite: null,
        entreprise: this.entreprise.id,
      }
      this.$store.dispatch("annonce/annonceSuccess", "Document ajouté")
    }
  },
  computed: {
    entreprise_attachments_types() {
      return this.$store.getters["select/entreprise_attachments_types"];
    },
    selected_dossier() {
      return this.$store.getters["dossier/getSelectedDossier"];
    },
    fetch_status() {
      return this.$store.getters["dossier/getSingleItemFetchStatus"];
    },
  }
}
</script>
